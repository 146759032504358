import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import connect from 'wc-context-redux/connect';
import { load, resetCompositionState } from '../../store/composition/composition.js';
import { compositionTypes } from '../../store/composition/helpers.js';
import { launchElearning } from '../../store/elearning/elearning.js';
import { translate } from '../../helpers/translate.js';
import '@sdblearning/sdb-learning-frontend';
import '@polymer/paper-dialog';
import '../shared/sdb-overlay-element.js';

const mapStateToProps = state => ({
    composition: state.composition.data,
    ready: !state.composition.isLoading && !!state.composition.data,
    noProgress: !state.composition.data || !state.composition.data.progress || state.composition.data.progress === 'started'
});

const mapDispatchToProps = dispatch => ({
    load: (compositionId) => dispatch(load({ compositionId })),
    launchElearning: (item) => dispatch(launchElearning({ item })),
    resetComposition: (compositionId) => dispatch(resetCompositionState({ compositionId })),
});

@customElement('sdb-composition-split-button')
class SdbCompositionSplitButton extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor compositionId;

    @property({type: Boolean})
    accessor startDisabled = false;

    @property({type: Boolean})
    accessor resetDisabled = false;

    @property({type: Boolean})
    accessor light = false;

    @state()
    accessor composition;

    @state()
    accessor ready = false;

    get caption() {
        switch (this.composition.progress) {
            case 'incomplete': return translate('btnIncomplete');
            case 'completed': return translate('btnCompleted2');
            default: return translate('btnStart');
        }
    }

    get isSingleItem() {
        return this.composition.compositionType === compositionTypes.COMPOSITIONTYPE_SINGLEITEM
            && this.composition.items.length > 0;
    }

    static get styles() {
        return css`
            sdb-content-split-button[light] {
                --text-light: var(--tcg-primary-color-ontertiary, #fff);
            }

            paper-dialog {
                max-width: 700px !important;
            }
        `;
    }

    render() {
        if (!this.ready)
            return nothing;

        if (this.noProgress) {
            return html`
                <sdb-content-button
                    label=${this.caption}
                    @click=${(e) => { this._executeStart(e); }}>
                </sdb-content-button>
            `;
        }

        return html`
            ${this.composition.progress !== 'completed' ? html`
                <sdb-content-split-button
                    primaryLabel=${this.caption}
                    paddingSplitButton="10px"
                    menuDismissible
                    ?disabled=${this.loading || this.startDisabled}
                    .primaryAction=${(e) => { this._executeStart(e); }}
                    .actions=${[
                        {
                            label: translate('btnReset'),
                            action: (e) => { this._openResetDialog(e); },
                            disabled: this.resetDisabled
                        },
                    ]}>
                </sdb-content-split-button>`: html`
                <sdb-content-split-button
                    primaryLabel=${translate('btnReset')}
                    paddingSplitButton="10px"
                    menuDismissible
                    ?disabled=${this.loading || this.resetDisabled}
                    .primaryAction=${(e) => { this._openResetDialog(e); }}
                    .actions=${[
                        {
                            label: this.caption,
                            action: (e) => { this._executeStart(e); },
                            disabled: this.startDisabled,
                        },
                    ]}>
                </sdb-content-split-button>
            `}

            <sdb-overlay-element>
                <paper-dialog
                    id="sdb-composition-reset-dialog"
                    style="max-width: 800px;"
                    modal>
                    <h2>${translate('dlgResetHeader')}</h2>
                    <p>${unsafeHTML(translate(this.isSingleItem ? 'dlgResetBodySingleItem' : 'dlgResetBody', {
                        catalogName: this.composition.title
                    }))}</p>
                    <div class="buttons">
                        <sdb-content-button
                            label=${translate('btnCancel')}
                            dialog-dismiss
                            media>
                        </sdb-content-button>
                        <sdb-content-button
                            label=${translate('btnReset')}
                            dialog-confirm
                            secondary
                            @click=${(e) => { this._executeReset(e); }}>
                        </sdb-content-button>
                    </div>
                </paper-dialog>
            </sdb-overlay-element>
        `;
    }

    firstUpdated() {
        if (this.compositionId) {
            this.load(this.compositionId);
        }
    }

    _executeStart(e) {
        if (this.isSingleItem) {
            this.launchElearning(this.composition.items[0]);
        }

        this.dispatchEvent(
            new CustomEvent('start', {
                bubbles: true,
                composed: true,
                detail: {
                    isSingleItem: this.isSingleItem,
                },
                target: e.target
            })
        );
    }

    _executeReset(e) {
        this.resetComposition(this.composition.id);

        this.dispatchEvent(
            new CustomEvent('reset', {
                bubbles: true,
                composed: true,
                detail: {
                    isSingleItem: this.isSingleItem,
                },
                target: e.target
            })
        );
    }

    _openResetDialog() {
        // our dialog can now be found in the regular dom
        document.getElementById('sdb-composition-reset-dialog').opened = true;
    }
}
